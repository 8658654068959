import {getFormProps, getInputProps, useForm} from '@conform-to/react'
import {getZodConstraint, parseWithZod} from '@conform-to/zod'
import {type ActionFunctionArgs, json, type LoaderFunctionArgs, type MetaFunction,} from '@vercel/remix'
import {Form, Link, useActionData, useSearchParams} from '@remix-run/react'
import {HoneypotInputs} from 'remix-utils/honeypot/react'
import {z} from 'zod'
import {GeneralErrorBoundary} from '#app/components/error-boundary.tsx'
import {CheckboxField, ErrorList, Field} from '#app/components/forms.tsx'
import {Spacer} from '#app/components/spacer.tsx'
import {StatusButton} from '#app/components/ui/status-button.tsx'
import {login, requireAnonymous} from '#app/utils/auth.server.ts'
import {ProviderConnectionForm, providerNames,} from '#app/utils/connections.tsx'
import {checkHoneypot} from '#app/utils/honeypot.server.ts'
import {useIsPending} from '#app/utils/misc.tsx'
import {PasswordSchema, UsernameSchema} from '#app/utils/user-validation.ts'
import {handleNewSession} from './login.server.ts'
import {format} from 'date-fns'
import { getUserById } from '#app/domain/user/user-service.ts'
import { invariantResponse } from '@epic-web/invariant'
import { motion, LazyMotion, domAnimation } from 'framer-motion';
import {PulsatingButton} from "#app/components/common/PulsatingButton.tsx";
import type {SEOHandle} from "#node_modules/@nasa-gcn/remix-seo";
import { AnimatedTitle } from '#app/components/common/AnimatedTitle.js'

export const handle: SEOHandle = {
	getSitemapEntries: () => null,
}

const LoginFormSchema = z.object({
	username: UsernameSchema,
	password: PasswordSchema,
	redirectTo: z.string().optional(),
	remember: z.boolean().optional(),
})

export async function loader({ request }: LoaderFunctionArgs) {
	await requireAnonymous(request)
	return json({})
}

export async function action({ request }: ActionFunctionArgs) {
	await requireAnonymous(request)
	const formData = await request.formData()
	checkHoneypot(formData)
	const submission = await parseWithZod(formData, {
		schema: (intent) =>
			LoginFormSchema.transform(async (data, ctx) => {
				if (intent !== null) return { ...data, session: null }

				console.log('data', data)
				const session = await login(data)
				if (!session) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Invalid username or password',
					})
					return z.NEVER
				}

				console.log('session', session)

				return { ...data, session }
			}),
		async: true,
	})

	if (submission.status !== 'success' || !submission.value.session) {
		return json(
			{ result: submission.reply({ hideFields: ['password'] }) },
			{ status: submission.status === 'error' ? 400 : 200 },
		)
	}

	const { session, remember, redirectTo } = submission.value
	const today = format(new Date(), 'yyyy-MM-dd')

	// Fetch user by ID
	const user = await getUserById(session.userId)
	invariantResponse(user, 'User not found')
	const dashboardRedirect = `/dashboard/${user.username}/date/${today}`

	return handleNewSession({
		request,
		session,
		remember: remember ?? false,
		redirectTo: redirectTo || dashboardRedirect,
	})
}

export default function LoginPage() {
	const actionData = useActionData<typeof action>()
	const isPending = useIsPending()
	const [searchParams] = useSearchParams()
	const redirectTo = searchParams.get('redirectTo')
	const today = format(new Date(), 'yyyy-MM-dd')

	const [form, fields] = useForm({
		id: 'login-form',
		constraint: getZodConstraint(LoginFormSchema),
		defaultValue: { redirectTo },
		lastResult: actionData?.result,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: LoginFormSchema })
			},
		shouldRevalidate: 'onBlur',
	})

	// Remove this part as we can't reliably set the username here
	// if (!fields.redirectTo.value) {
	// 	fields.redirectTo.value = `/dashboard/${fields.username.value}/date/${today}`
	// }

	return (
		// <div className="flex min-h-full flex-col justify-center pb-32 pt-20 bg-gradient-to-b from-yellow-50 to-white">
		// <div className="mx-auto w-full max-w-md">

		// <main className="container flex flex-col justify-center pb-32  bg-gradient-to-b from-yellow-50 to-white">
		// 	<div className="min-h-screen bg-white">
		<div className="container flex flex-col justify-center pb-32 pt-20 text-gray-800 ">
			<div className="flex flex-col gap-3 text-center">
				<AnimatedTitle>Glad to see you back!</AnimatedTitle>
			</div>
			<Spacer size="xs"/>

			<div>
				<div className="mx-auto w-full max-w-md px-8">
					<Form method="POST" {...getFormProps(form)}>
						<HoneypotInputs/>
						<Field
							labelProps={{children: 'Username', className: 'text-gray-700'}}
							inputProps={{
								...getInputProps(fields.username, {type: 'text'}),
								autoFocus: true,
								className: 'lowercase border-gray-300 text-gray-800',
								autoComplete: 'username',
							}}
							errors={fields.username.errors}
						/>

						<Field
							labelProps={{children: 'Password', className: 'text-gray-700'}}
							inputProps={{
								...getInputProps(fields.password, {
									type: 'password',
								}),
								className: 'border-gray-300 text-gray-800 mb-4',
								autoComplete: 'current-password',
							}}
							errors={fields.password.errors}
						/>

						<div className="flex justify-between">
							<CheckboxField
								labelProps={{
									htmlFor: fields.remember.id,
									children: 'Remember me',
									className: 'text-gray-700',
								}}
								buttonProps={getInputProps(fields.remember, {
									type: 'checkbox',
								})}
								errors={fields.remember.errors}
							/>
							<div>
								<Link
									to="/forgot-password"
									className="text-body-xs font-semibold text-indigo-900 hover:text-indigo-700 underline"
								>
									Forgot password?
								</Link>
							</div>
						</div>

						<input
							{...getInputProps(fields.redirectTo, {type: 'hidden'})}
						/>
						<ErrorList errors={form.errors} id={form.errorId}/>

						<div className="flex items-center justify-between gap-6 pt-3">
							<StatusButton
								className="w-full bg-indigo-900 hover:bg-indigo-800 text-white font-bold text-base py-2 px-4 rounded transition duration-300 ease-in-out"
								status={isPending ? 'pending' : form.status ?? 'idle'}
								type="submit"
								disabled={isPending}
							>
								Submit
							</StatusButton>
						</div>

						<div className="flex items-center justify-between gap-6 pt-3 w-full">
							{/* <PulsatingButton
										className="w-full"
										status={isPending ? 'pending' : form.status ?? 'idle'}
										type="submit"
										disabled={isPending}
									>
										Log in
									</PulsatingButton> */}
						</div>
					</Form>
					{/* <ul className="mt-5 flex flex-col gap-5 border-b-2 border-t-2 border-border py-3">
							{providerNames.map((providerName) => (
									<li key={providerName}>
										<ProviderConnectionForm
											type="Login"
											providerName={providerName}
											redirectTo={redirectTo}
										/>
									</li>
								))}
						</ul> */}
					<div className="flex items-center justify-center gap-2 pt-6">
						<span className="text-gray-600">New here?</span>
						<Link
							to={
								redirectTo
									? `/signup?${encodeURIComponent(redirectTo)}`
									: '/signup'
							}
							className="text-indigo-900 hover:text-indigo-700 font-semibold underline"
						>
							Create an account
						</Link>
					</div>
				</div>
			</div>
		</div>
			// </div>
		// </main>
		// </div>
		// </div>
	)
}

export const meta: MetaFunction = () => {
	return [{title: 'Login to best of me' }]
}

export function ErrorBoundary() {
	return <GeneralErrorBoundary />
}
